/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

  		var $hamburger = $(".hamburger");
  		$hamburger.on("click", function(e) {
        e.preventDefault();
  			$hamburger.toggleClass("is-active");
  			// Do something else, like open/close menu
  			$("#header .navbar").toggleClass("is-open");
  			$("body").toggleClass("menu-is-open");
  		});

  		$('.navbar-right a').on('click', function(e){
        e.preventDefault();
  			$('#header .navbar').removeClass("is-open");
  			$hamburger.removeClass("is-active");
  		});

		  /*
		  var $project_close_button = $("#project_close_button");
		  $project_close_button.on("click", function(e) {
			$("article.project").toggleClass("is-active");
			// Do something else, like open/close menu
		});*/
		/*$('.slick-project').slick({
		  dots: true,
		  infinite: true,
		  speed: 300,
		  slidesToShow: 1,
		  centerMode: false,
		  variableWidth: true
		});

		  function toggleVideo(state) {
			// if state == 'hide', hide. Else: show video
			var div = document.getElementById("project_video");
			var iframe = div.getElementsByTagName("iframe")[0].contentWindow;
			iframe.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
		}
		  // On swipe event
		$('.slick-project').on('swipe', function(event, slick, direction){
		 	toggleVideo();
		  // left
		});

		  $('button.slick-arrow').on('click', function(e){
		 	toggleVideo();
		});*/

		  $('#block-map').on('click', function(e){
  			$('#block-map iframe').css("pointer-events", "auto");
  		});

  		$( "#block-map" ).on('mouseleave', function(e){
  		  $('#block-map iframe').css("pointer-events", "none");
  		});


		  $(".person-picture").on("click", function(e) {
			  $( ".person-picture.on" ).removeClass("on").not( $( this ) );

			  $(this).toggleClass("on");
		  });

		  $("#search_reveal").on("click", function(e) {
  			$(".search_breadcrumbs").toggleClass("show_search");
  			  $( ".search-field" ).focus();
  			// Do something else, like open/close menu
  		});

  		  //Enable swiping...
			$(".carousel-inner").swipe( {
				//Generic swipe handler for all directions
				swipeLeft:function(event, direction, distance, duration, fingerCount) {
					$(this).parent().carousel('next');
				},
				swipeRight: function() {
					$(this).parent().carousel('prev');
				},
				//Default is 75px, set to 0 for demo so any distance triggers swipe
				threshold:0
      });

		  /* function detect_scrolled(){

			if($(window).scrollTop() > 100){
				$( "#header" ).addClass( "scrolled" );
			} else{
				$( "#header" ).removeClass( "scrolled" );
			}

			& var scrolledY = $(window).scrollTop();
  			$('#block-0446961a .cta-common').css('background-position', 'center ' + ((scrolledY)) + 'px');

		  }

		  var scrollInterval = null;

  		$(window).scroll(function(){ detect_scrolled(); });

  		$(window).on('touchmove', function() {
  			scrollInterval = window.setInterval("detect_scrolled", 100);
  		});
  		$(window).on('touchend', function() {
  			clearInterval(scrollInterval);
  		});

  		if($(window).scrollTop() > 100){

  			$( "#header" ).addClass( "scrolled" );
  		} */

  		/*$('#return-to-top').on("click", function(e) {    // When arrow is clicked
  			$('body,html').animate({
  				scrollTop : 0                       // Scroll to top of body
  			}, 500);
  		});*/

  		$('#filter_form select').on('change', function(e) {
  			$(this).closest('form').trigger('submit');
  		  });

  		var offset;
          offset = $('nav.navbar').innerHeight();

  		/*$(function() {
  		  $('body').on("click", 'a[href*="#"]:not([href="#"],[data-slide="prev"],[data-slide="next"],[data-toggle="collapse"],[data-toggle="tab"],[href="#tab"])', function() {
  			if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
  				var target = $(this.hash);
  				target = (target.length ? target : $('[name=' + this.hash.slice(1) +']'));
  				if (target.length) {
  				  $('html,body').animate({
  					scrollTop: target.offset().top - offset
  				  }, 1000);
  				  $('.navbar').addClass("scrolled");
  				  return false;
  				}
  			  }
  		  });
  		});*/

		  /*var urlHash1 = window.location.href.split("#")[1];
			if ($('#' + urlHash1).length) {
				$('html,body').animate({
					scrollTop: $('#' + urlHash1).offset().top - offset
				}, 1000);
			}*/

      $(window).scroll(function() {
          var scroll = $(window).scrollTop();

          if (scroll >= $(window).innerHeight() - 50) {
              $("#header").addClass("scrolled-header");
              $('.main-logo').fadeOut(400);
              $('.alt-logo').fadeIn(400);
          } else {
              $("#header").removeClass("scrolled-header");
              $('.main-logo').fadeIn(400);
              $('.alt-logo').fadeOut(400);
          }
      });

      // Remove classes from text box if hasClass '.block-free-review'
      $('#block-free-review .one-column').removeClass('col-md-6 col-md-offset-3');

      // matchHeight
      $(function(){
        $('.col-xs-6.stat').matchHeight();
      });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
